import React from "react";
import {ApolloClient, HttpLink, InMemoryCache, split} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {createClient} from 'graphql-ws';

// ge: change into a factory function, to avoid error with gatsby build
export function getClient() {
    // ge: bypass the entire factory call to prevent Gatsby error
    if (typeof window == "undefined") return null;
    const httpLink = new HttpLink({
        uri: 'http://localhost:4000/graphql'
    });

    // ge: only add wsLink if it is in the browser. To prevent Gatsby error
    const wsLink = new GraphQLWsLink(createClient({
        url: 'ws://localhost:4000/subscription',
    }));


// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
        ({query}) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    return new ApolloClient({
        // socketURI: 'https://api-dash-ml.herokuapp.com',
        // socketURI: 'http://localhost:4000/graphql',
        link: splitLink,
        cache: new InMemoryCache(),
    });

}