import React, {useMemo} from 'react';
import {ApolloProvider} from '@apollo/client';
import {EditNote} from "./_edit-note";
import {ShowNote} from "./_show-note";
import {Link, Router} from "@reach/router";
import {withPrefix} from 'gatsby';
import {getClient} from "./_ap-client";

export default function VqnApp() {
    const client = useMemo(getClient)
    if (typeof window == "undefined") return <div>VQN Dash</div>;
    return (
        <ApolloProvider client={client}>
            <div>
                <nav className="nav">
                    <Link to="/demos/vqn-dash-dev/view">/view</Link>{"   "}
                    <Link to="/demos/vqn-dash-dev/edit">/edit</Link>
                </nav>
                <Router basepath={withPrefix("/demos/vqn-dash-dev")}>
                    <ShowNote path="/view"/>
                    <EditNote path="/edit"/>
                </Router>
            </div>
        </ApolloProvider>
    );
}
