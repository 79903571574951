import React from "react";
import styled from "styled-components";


const ListContainer = styled.div`
`;

export default function NoteList({collection = [], cardComponent: Card, loading = null, error = null}) {
    if (loading) return <div>is loading</div>
    if (!collection) {
        return <ListContainer>{
            <Card placeholder={"Create New Note"}/>
        }</ListContainer>
    }
    return <ListContainer>{
        collection.map(({slug, title, body}) => {
            return <Card key={slug} slug={slug} title={title} text={body}/>
        })
    }</ListContainer>
}