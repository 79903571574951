import React from "react";
import {NoteCollection, NoteData} from "./collections/_Notes";
import _noteView, {SnippetView} from "./components/_note-view";
import NoteList from "./components/_note-list";


// const randSlug = "notes-31832";
// client.query({
//     query: gql`
//         query {
//             note (
//                 id: "6338670f978338ea240a031f"
//             ){
//                 slug title body
//             }
//         }
//     `
// }).then((result) => console.log(result))


export function ShowNote({params}) {
    console.log(params)
    return <>
        <div>show note div</div>
        <NoteCollection component={NoteList} cardComponent={SnippetView}/>
        <NoteData id={"6338670f978338ea240a031f"} component={_noteView}/>
    </>
}