import React from "react";
import Editor from "@monaco-editor/react";

export function SnippetView(
    {
        slug = null,
        title = null,
        text = null,
        placeholder = null,
        loading = null,
        error = null
    }) {
    if (loading) return <div>is loading</div>
    if (!!placeholder) return <div>Create new note</div>
    return <div>
        <div>{slug}</div>
        <article>
            <h1>{title}</h1>
            {text.slice(0, 20)}
        </article>
    </div>
}

export default function _noteView({slug, title, text, loading = null, error = null}) {
    if (loading) return <div>is loading</div>
    return <div>
        <div>{slug}</div>
        <article>
            <h1>{title}</h1>
            {text}
        </article>
    </div>
}

export function NoteEditor(
    {
        slug, title, text,
        onUpdate,
        loading = null, error = null
    }) {
    if (loading) return <div>is loading</div>

    function onChange(value, event) {
        console.log(value)
        onUpdate({slug, title, text})
    }

    return <div>
        <div>{slug}</div>
        <h1>{title}</h1>
        <Editor height="90vh" defaultLanguage="markdown" defaultValue={text} onChange={onChange}/>
    </div>
}
