import React from 'react';
import {NoteCollection, NoteData, NoteEdit} from "./collections/_Notes";
import NoteList from "./components/_note-list";
import _noteView, {NoteEditor, SnippetView} from "./components/_note-view";

export function EditNote({params}) {
    console.log(params)
    return <>
        <div>edit note div</div>
        <NoteCollection component={NoteList} cardComponent={SnippetView}/>
        <NoteEdit id={"6338670f978338ea240a031f"} component={NoteEditor}/>
    </>
}

