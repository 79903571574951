import React, {Component} from "react";
import {gql, useMutation, useQuery} from '@apollo/client';

export const NOTE_QUERY = gql`
    query {
        note (
            id: "6338670f978338ea240a031f"
        ){
            slug title body
        }
    }
`;

export const NOTE_COLLECTION = gql`
    query {
        notes (
            userId: "63386713978338ea240a0321"
        ){
            slug title body
        }
    }
`;

export const NOTE_EDIT = gql`
    mutation EditNote ($slug: String!, $title: String, $text: String) {
        updateNote(
            slug: $slug,
            title: $title,
            body: $text
        ){
            slug title body
        }
    }
`

// export const NOTE_SUBSCRIBE = gql`
//     subscription {
//         updateNote(
//             slug: $slug,
//             title: $title,
//             body: $text
//         ){
//             slug title body
//         }
//     }
// `

export function NoteData({id, component: Component, ...rest}) {
    const {loading, error, data} = useQuery(NOTE_QUERY,);
    return <Component loading={loading} error={error}
                      slug={data?.note?.slug}
                      title={data?.note?.title}
                      text={data?.note?.body}
                      {...rest}
    >loading</Component>;
}

export function NoteCollection({id, component: Component, ...rest}) {
    const {loading, error, data} = useQuery(NOTE_QUERY,);
    const collection = data?.notes || [];
    console.log("collection:", collection)
    return <Component loading={loading} error={error}
                      collection={collection} {...rest}
    >loading</Component>;
}

export function NoteEdit({id, component: Component, ...rest}) {
    const [onEdit, {data, loading, error}] = useMutation(NOTE_EDIT);
    // const {loading, error, data} = useQuery(NOTE_QUERY,);
    console.log("note data:", data)
    return <Component loading={loading}
                      error={error}
                      onUpdate={onEdit}
                      slug={data?.note?.slug}
                      title={data?.note?.title}
                      text={data?.note?.body}
                      {...rest}
    >loading</Component>;
}
